import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// import pdf1 from 'assests/Pdf/CCA/Associationactivity2021-22.pdf';
// import pdf2 from 'assests/Pdf/CCA/INTERSCHOOLPPT2021-22.pdf';


import Main from 'layouts/Main';
import ACHCOS2223 from './ACHCOS2223';
import ACHCOS2023 from './ACHCOS2023';

import Container from 'components/Container';
import { Button } from '@mui/material';
import ACHCOS2024 from './ACHCOS2024';




const ACHCOS = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const pdf1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/CCA/Associationactivity2021-22.pdf`;
  const pdf2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/CCA/INTERSCHOOLPPT2021-22.pdf`;
  

  const theme = useTheme();

  const mock = [
    {
      name: '',
      title: 'ASSOCIATION ACTIVITY 2021-22',
      
      href:pdf1,
    },
    {
      name: '',
      title: 'INTER SCHOOL COMPETITION WINNERS  ',
     
      href:pdf2,
    },
  
  
  ];

  return (
    <Main>
       <Container>
      <ACHCOS2024/>
    </Container>
       <Container>
      <ACHCOS2023/>
    </Container>

    <Container>
      <ACHCOS2223/>
    </Container>
    
    <Container>
      
      <Box>
        <Box marginBottom={1}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
          Co-Scholastic 2021-22
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={0.9}
                height={0.9}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href} target="_blank"> 
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
    
   
    </Main>
  );
};

export default ACHCOS;

